<template>
  <v-card :class="rule.color" class="d-block" flat>
    <v-card-text :class="`${modelValue.value !== 'reject' ? 'py-0' : 'py-8'} px-5`">
      <div class="d-flex ma-0">
        <div class="d-flex align-center cols-4">
          <div class="subtitle-2 grey-500--text font-weight-semibold">{{ rule.title }}</div>
          <div class="pa-0 pl-5">
            <v-autocomplete
              v-model="modelValue.value"
              :items="rule.items"
              :menu-props="{ overflowY: true, transition: 'slide-y-transition' }"
              height="30"
              item-text="text"
              item-value="id"
              single-line
              outlined
              dense
              hide-details
              placeholder="Selecciona una alternativa"
              @input="$emit('update:model', modelValue)"
            />
          </div>
        </div>
        <v-spacer />
        <div class="d-flex text-right pa-0" v-if="modelValue.value !== 'reject'">
          <img class="ma-0" :src="require(`@/assets/automation/${modelValue.value?.toLowerCase()}.svg`)" :height="100" />
        </div>
        <template v-if="modelValue.value === 'reject'">
          <div class="d-flex align-center px-5" cols="5">
            <div class="subtitle-2 grey-500--text">Por</div>
            <div class="pa-0 pl-5">
              <v-autocomplete
                v-model="modelValue.exchange_status"
                :items="byList"
                :menu-props="{ overflowY: true, transition: 'slide-y-transition' }"
                height="30"
                item-text="text"
                item-value="value"
                single-line
                outlined
                dense
                hide-details
              />
            </div>
          </div>
          <div class="d-flex align-center">
            <div class="subtitle-2 grey-500--text">En un plazo de</div>
            <div class="py-0 px-3" cols="5">
              <v-select
                v-model="modelValue.days"
                :items="[1, 2, 3, 4, 5, 6, 7]"
                height="30"
                item-text="text"
                item-value="id"
                single-line
                outlined
                dense
                hide-details
              />
            </div>
            <div class="subtitle-2 grey-500--text">días</div>
          </div>
        </template>
      </div>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  props: {
    rule: {
      type: Object,
      default: () => {}
    },
    model: {
      type: Object,
      default: () => {}
    }
  },
  watch: {
    modelValue (val) {
      this.$emit('update:model', val)
    }
  },
  created () {
    this.modelValue = {
      value: this.rule.type.toLowerCase(),
      exchange_status: this.rule?.value?.exchange_status ?? null,
      days: this.rule?.value?.days ?? null
    }
  },
  data: () => ({
    modelValue: {
      value: null,
      exchange_status: null,
      days: 0
    },
    byList: [
      {
        text: 'Reclamo contenido del documento',
        value: 'RCD'
      },
      {
        text: 'Reclamo por falta parcial de mercaderías',
        value: 'RFP'
      },
      {
        text: 'Reclamo por falta total de mercaderías',
        value: 'RFT'
      }
    ]
  })
}
</script>